import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyKeyType } from '@rhbnb-nx-ws/domain';
import { CurrencyService } from '@rhbnb-nx-ws/services';
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'currencyConvert',
})
export class CurrencyConvertPipe implements PipeTransform {
  constructor(private currencyService: CurrencyService) {}

  transform(value: number, ...args: any[]): any {
    const {
      cupRate,
      currency,
      currencyTable$,
      currentCurrency$,
      ignoreCurrency,
    } = args[0];
    const _ignoreCurrency = ignoreCurrency ? ignoreCurrency : [];

    return combineLatest([
      currencyTable$,
      currency ? of(currency) : currentCurrency$
    ]).pipe(
      map(([table, _currency]) =>
        _ignoreCurrency.includes(_currency)
          ? value
          : this.currencyService.convert(
              CurrencyKeyType.USD,
              _currency as CurrencyKeyType,
              value,
              this.currencyService.mergeConversionTableWithNewCupValue(
                table,
                cupRate
              )
            )
      ),
    );
  }
}
